@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

.hidden, .cms-toolbar, .header .menu-toggle,
.header .nav, .header .inner *, .hero .inner *, .column.right, .column.left, .pre-footer, .search-container, .social-icons,
.footer-links, .cookies, .nav-wrapper,
.mobile-ui,
.footer-top,
.footer-accreditations, .staff-search-container .staff-search-header p.h1, .staff-search-container .department-container, .section-team .staff-list .has-icon .icon,
.section-people .staff-list .has-icon .icon, .section-team .staff-list .staff-image,
.section-people .staff-list .staff-image {
  display: none; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loading p {
    color: #fff; }
  .loading .dot {
    width: 14px;
    height: 14px;
    margin: 0 7px;
    background: #fff; }
  .loading p {
    margin: 0;
    text-align: center; }
  .loading .dot {
    display: inline-block;
    transform: scale(0);
    border-radius: 50%;
    animation: loading 1s ease infinite; }
    .loading .dot:nth-child(2) {
      animation: loading 1s ease infinite 0.3s; }
    .loading .dot:nth-child(3) {
      animation: loading 1s ease infinite 0.6s; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

.centre .content * + ul, .centre .content * + ul li ul, .centre .content * + ol, .centre .content * + ol li ol {
  margin: 0 0 2rem 20px; }

.centre .content * + ul, .centre .content * + ul li ul {
  list-style: disc; }

.centre .content * + ol, .centre .content * + ol li ol {
  list-style: decimal; }

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0; }

.button {
  border: none;
  border-radius: 4px;
  background: #282731;
  color: #fff;
  text-align: center; }

.button:hover {
  background: #00A78F;
  color: #fff; }

html {
  font-size: 95%; }
  @media only screen and (min-width: 40em) {
    html {
      font-size: 100%; } }
  @media only screen and (min-width: 60em) {
    html {
      font-size: 105%; } }

body {
  color: #282731;
  font-family: "Noto Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.7; }

em strong {
  color: #00A78F; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 2rem;
  color: #282731;
  font-family: "Noto Serif", serif;
  font-weight: 300;
  line-height: 1.3; }

h1, .h1 {
  font-size: 2.8rem; }

h2, .h2 {
  font-size: 2.2rem; }

h3, .h3 {
  font-size: 1.7rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.3rem; }

h6, .h6 {
  font-size: 1.1rem; }

p {
  margin: 0; }

strong {
  font-weight: 600; }

hr {
  border: 1px solid #eee; }

a {
  color: #282731;
  text-decoration: none;
  transition: all 0.2s ease-out; }
  a:hover {
    color: #00A78F; }

.column .table-scroll {
  overflow: scroll;
  margin: 0 0 2rem; }
  .column .table-scroll table {
    width: 100%; }
    .column .table-scroll table th {
      padding: 1rem 1.5rem;
      border: 2px solid #1c1c23;
      background: #282731;
      color: #fff;
      font-family: "Noto Serif", serif; }
    .column .table-scroll table td {
      padding: 1.5rem;
      border: 2px solid #eee;
      vertical-align: top; }
    .column .table-scroll table p {
      margin: 0; }

.centre .content p {
  margin: 0 0 2rem; }
  .centre .content p.intro {
    padding-bottom: 2rem;
    border-bottom: 1px solid #eee;
    color: #918E9C;
    font-family: "Noto Serif", serif;
    font-size: 1.5rem; }
    @media only screen and (min-width: 40em) {
      .centre .content p.intro {
        font-size: 1.7rem; } }
  .centre .content p a {
    color: #00A78F; }
    .centre .content p a:hover {
      color: #918E9C; }

.centre .content a {
  font-weight: 600; }

.centre .content * + ul li {
  margin-bottom: 0.5rem; }
  .centre .content * + ul li a {
    color: #00A78F; }
    .centre .content * + ul li a:hover {
      color: #918E9C; }

.centre .content blockquote {
  margin: 0;
  padding: 0; }

.button {
  display: inline-block;
  padding: 0.5rem 3rem;
  border-radius: 4px;
  transition: all 0.3s ease-out; }
  .button a {
    color: inherit;
    transition: inherit; }

.hidden, .cms-toolbar, .header .menu-toggle,
.header .nav, .header .inner *, .hero .inner *, .column.right, .column.left, .pre-footer, .search-container, .social-icons,
.footer-links, .cookies, .nav-wrapper,
.mobile-ui,
.footer-top,
.footer-accreditations, .staff-search-container .staff-search-header p.h1, .staff-search-container .department-container, .section-team .staff-list .has-icon .icon,
.section-people .staff-list .has-icon .icon, .section-team .staff-list .staff-image,
.section-people .staff-list .staff-image {
  display: none; }

* {
  color: #111;
  background: none; }

.wrapper {
  padding-bottom: 0 !important;
  padding: 2rem; }

.header {
  margin-bottom: 3rem; }
  .header .inner .company-logo-link,
  .header .inner .company-logo {
    display: block; }

.hero .inner .profile-image-wrapper,
.hero .inner .profile-image {
  display: block; }

.hero .inner .profile-image-wrapper {
  margin-bottom: 3rem; }

.section-team .staff-list,
.section-people .staff-list {
  margin-bottom: 3rem; }
  .section-team .staff-list .card,
  .section-people .staff-list .card {
    margin-bottom: 2rem; }

#ccc-notify {
  display: none !important; }
