
//==========  Colors  ==========//

// set our colour variables
// avoid using obvious declarative names as the colours may not always match the name
// e.g, $colour-orange
// 
// we also create lighter and darker tones for use with hover states, etc.

$color-primary: 		#282731; 						// the brand colour
$color-primary-light:	lighten($color-primary, 5%);	// the brand colour - lighter
$color-primary-dark:	darken($color-primary, 5%);		// the brand colour - darker

$color-secondary: 		#00A78F; 						// the accent colour
$color-secondary-light:	lighten($color-secondary, 5%);	// the accent colour - lighter
$color-secondary-dark:	darken($color-secondary, 5%);	// the accent colour - darker

$color-tagline:			$color-primary;						// tagline colour

//==========  Tones  ==========//

// set grey tones variables
// these will typically be used for borders and form inputs
// again, we also create lighter and darker tones for use with hover states, etc.

$color-lightgrey:		#eee;							// a default light grey
$color-lightgrey-light:	lighten($color-lightgrey, 5%);	// a default light grey - lighter
$color-lightgrey-dark:	darken($color-lightgrey, 5%);	// a default light grey - darker

$color-midgrey:			#bbb;	 						// a default mid grey
$color-midgrey-light:	lighten($color-midgrey, 5%);	// a default mid grey - lighter
$color-midgrey-dark:	darken($color-midgrey, 5%);		// a default mid grey - darker

$color-darkgrey:		#85898a;						// a default dark grey
$color-darkgrey-light:	lighten($color-darkgrey, 5%);	// a default dark grey - lighter
$color-darkgrey-dark:	darken($color-darkgrey, 5%);	// a default dark grey - darker

$color-white: 			#fff;							// a default white
$color-black: 			#111;							// a default black
$color-form-background: #F7F7F7;						// Form background colour

$pre-footer-background: $color-form-background;			// Pre-footer colour
$twitter-link:			#918E9C;						// twitter link colour
$color-white-faded: 	rgba(255,255,255,0.5);			// faded-color-white;
$office-white-faded:	rgba(255,255,255,0.3);			// office border;
$cta-border-color:		rgba(200,200,200,0.9);			// CTA Border Colour
$phone-color:			#868686;						// phone colour

//==========  Text colors  ==========//

// set our default text colours for titles, paragraphs, etc.

// $color-text:	#717f81;								// body text colour
$color-text:	#282731;								// body text colour
$color-sub:		#918E9C;								// sub and intro text colour
$color-title:	$color-primary;							// heading text colour


