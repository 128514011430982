
//==========  Borders  ==========//

// build our default border style 

$border-size: 1px;
$border-style: solid;
$border-color: $color-lightgrey;
$border-white: $color-white;
$slider-border-size: 4px;
// combine border variables into a default
// this is used throughout our modules as a base
$border: $border-size $border-style $border-color;

// set a default border-radius
// this is used in our forms and search styling
$border-radius: 4px;

$slider-border: $slider-border-size $border-style $border-white;
$cta-border: $border-size $border-style $cta-border-color;
