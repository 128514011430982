
// Content styles
.centre .content {
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding-bottom: $paragraph-margin;
			border-bottom: $border;
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $h4;
			
			@media #{$mobileXL} {
				font-size: $h3;
			}
		}
		a {
			color: $color-secondary;

			&:hover {
				color: $color-sub;
			}
		}
	}
	a{
		font-weight: $font-bolder;
	}
	
	// New default <ul> lists
	* + ul {
		@extend %default-ul;
		
		li {
			margin-bottom: $block-margin - 2.5rem;

			a {
			color: $color-secondary;

				&:hover {
					color: $color-sub;
				}
			}
			ul {
				@extend %default-ul;
			}
		}
	}
	
	// New default <ol> lists
	* + ol {
		@extend %default-ol;

		li {
			ol {
				@extend %default-ol;
			}
		}
	}

	blockquote {
		margin: 0;
		padding: 0;
	}
}

